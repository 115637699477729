import React, { Component } from 'react';
import './logoSection.css';
import DesignSection from './designSection';
import { AddToClipBoard } from './../../utils/functions';

let data = [
  {
    title:'Coverage Summary',
    src:'/design/email-banner-coverage.png'
  },
  {
    title:'Medtech',
    src:'/design/email-banner-medtech.png'
  },
  {
    title:'General',
    src:'/design/email-banner-data.png'
  },
  {
    title:'Signals',
    src:'/design/email-banner-signals.png'
  },
  {
    title:'Software',
    src:'/design/email-banner-software.png'
  },
  {
    title:'Investor',
    src:'/design/email-banner-investors.png'
  },
  {
    title:'Spendhound Renewals',
    src:'/design/email-banner-spendhound-renewal.png'
  },
  {
    title:'Spendhound CS',
    src:'/design/email-banner-spendhound-referral.png'
  },
  {
    title:'YipitData Logo',
    src:'/design/email-yipitdata-logo.png'
  },
  {
    title:'Spendhound Logo',
    src:'/design/email-spendhound-yipitdata-logo.png'
  }
]

export default class EmailSection extends Component {

  copyLink = (url) =>{
    AddToClipBoard('https://design.yipitdata.com'+url)
  }

  render() {
    return (
      <div className="sectionContainer">
        <DesignSection
          title={'Signature Banners'}
          subtitle={'Follow the instructions to add an image to your email signature:'}>
          <div class="tiny-padding" style={{maxWidth:"800", color:"#666666"}}>
            <p>1. Right click the image of your choosing and click "Copy Image Address"</p>
            <p>2. Open your Gmail settings by clicking the gear icon</p>
            <p>3. Scroll down to Email signature settings</p>
            <p>4. Place your cursor where you want to add the image</p>
            <p>5. Click the mountain icon to choose an image and select "Web Address (URL)" </p>
            <p>6. Paste the image URL</p>
            <p>7. Highlight the image and click the link icon (pro tip: the link shortcut is Command + K)</p>
            <p>8. Paste the website url "https://www.yipitdata.com" (or paste the url of your choosing)</p>
            <p>9. Click Save Changes at the bottom of the page</p>
          </div>
          <div style={{maxWidth:600}}>
            {data && data.map((item) =>{
              return(
                <div className="tiny-padding">
                  <p className="tiny-bottom-margin"><b>{item.title}</b></p>
                  <img src={item.src} className="tiny-bottom-margin" onClick={() => this.copyLink(item.src)} />
                </div>
              )
            })}
          </div>
        </DesignSection>
      </div>
    );
  }
}
